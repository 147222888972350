@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

body {
  scroll-behavior: smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .spin {
    animation: spin infinite 7s linear;
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  95% {
    transform: translateY(-2%);
  }
  100% {
    transform: translateY(0%);
  }
}

.slideDown {
  animation-name: slideDown;
  -webkit-animation-name: slideDown;

  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;

  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;

  visibility: visible !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.site-max {
  max-width: 1600px;
  margin: 0 auto;
}

@font-face {
  font-family: "Graphik";
  src: url("./assets/fonts/Graphik-Regular.woff") format("woff");
}

@font-face {
  font-family: "Graphik";
  src: url("./assets/fonts/Graphik-Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Graphik";
  src: url("./assets/fonts/Graphik-Semibold.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "Graphik";
  src: url("./assets/fonts/Graphik-Bold.woff") format("woff");
  font-weight: 700;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
